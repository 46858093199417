import { Authorities } from './authorities.model';


export class User {
//  ? significa opcional
    constructor(
        public idUsuario: string,
        public idEmpresa: string,
        public codEmpresa: number,
        public codSucursal: number,
      /*   public idSucursal: number, */
        public sub: string,
        public password: string,
        public authorities: Authorities[],
/*         public codEmpresaErp: string,
        public codSucursalErp: string, */
        // public nombre: string,
        // public username: string,
        public img?: string,
        public pin?: string,
    ) { }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { REQUEST_URL } from '../../config/config';
import { map, catchError } from 'rxjs/operators';
import { EstadoEmpaques } from '../../models/estadoEmpaques.model';
import { Observable } from 'rxjs/internal/Observable';
import { _throw as throwError } from 'rxjs/observable/throw';



@Injectable()
export class EstadoEmpaqueService {
  //estadoEmpaques: EstadoEmpaques[] = [];
  constructor(public http: HttpClient, public router: Router) { }

  getEstadosEmpaques(estado: string, fechaDesde: string, fechaHasta: string, codEmpresa: number, codSucursal: number) {
    return this.http.get(REQUEST_URL + 'estadoempaques?estado=' + estado + '&fechainicio=' + fechaDesde + '&fechafin=' + fechaHasta + '&codempresa=' + codEmpresa + '&codsucursal=' + codSucursal)
    .map((response: EstadoEmpaques[]) => {
      return response;
    });
  }

  postEstadosEmpaques(estadoEmpaque: EstadoEmpaques[]): Observable<EstadoEmpaques> {
    return this.http.post(REQUEST_URL + 'estadoempaques', estadoEmpaque)
      .pipe(
        map((response: any) => response.estado as EstadoEmpaques),
        catchError(e => {
          return throwError(e);
        })
      );
  }

  putEstadosEmpaques(estadoEmpaque: EstadoEmpaques[]): Observable<EstadoEmpaques> {
    return this.http.put(REQUEST_URL + 'estadoempaques', estadoEmpaque)
      .pipe(
        map((response: any) => response.estado as EstadoEmpaques),
        catchError(e => {
          return throwError(e);
        })
      );
  }
}

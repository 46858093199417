import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../../services/login/login.service';
import { Parametro } from '../../models/parametro.model';
import { ParametroService } from '../../services/parametro/parametro.service';
@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styles: []
})
export class ParametrosComponent implements OnInit {
  cargando: boolean = false;
  // paginador: any;
  // paginas = [];
  // busqueda: string = '';
  // totalElementos: number = 0;
  // rutaPaginador: string = '/parametros/page';
  parametros: Parametro[] = [];

  currentPage: number;
  pageSize: number;

  constructor(private _parametroService: ParametroService,
    private _loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public http: HttpClient
  ) {
    this.currentPage = 1;
    this.pageSize = 10;
  }

  ngOnInit() {
    this.getParametros();
  }


  delete(p: Parametro): void {
    Swal.fire({
      title: 'Está seguro?',
      text: `¿Seguro que desea eliminar el parametro ${p.descripcion}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this._parametroService.delete(p.id).subscribe(
          () => {
            this.parametros = this.parametros.filter(x => x !== p);
            Swal.fire(
              'Parametro Eliminado!',
              `Parametro ${p.descripcion} eliminado con éxito.`,
              'success'
            );
          }
        );

      }
    });
  }

  getParametros() {
    this._parametroService.get(this._loginService.user.idEmpresa)
      .subscribe((response: any) => {
        this.parametros = response as Parametro[];
        console.log(this.parametros);
      });
  }
}

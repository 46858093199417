import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as swal from 'sweetalert';
import { UsuarioService } from '../services/service.index';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

declare function init_plugins();

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {

  forma: FormGroup;

  constructor(// injectar servicio
    public _loginService: LoginService,
    public router: Router
  ) { }



  ngOnInit() {
      init_plugins();
      // los campos son los que necesito manejar desde el html
      this.forma = new FormGroup({
        nombre: new FormControl( null , Validators.required ),
        correo: new FormControl( null , [Validators.required, Validators.email] ), // validators es para poner validaciones
        password: new FormControl( null , Validators.required ),
        password2: new FormControl( null , Validators.required ),
        condiciones: new FormControl( false )
        // se realiza la validacion por medio de una funcion enviando parametros
      }, { validators: this.sonIguales( 'password', 'password2' )  } );

     // temporal
/*       this.forma.setValue({
        nombre: 'Test ',
        correo: 'test@test.com',
        password: '123456',
        password2: '123456',
        condiciones: true
      }); */

  }


 registrarUsuario() {
    // si es invalido..
    if ( this.forma.invalid ) {
      return;
    }

    if ( !this.forma.value.condiciones ) {
/*       swal('Importante', 'Debe de aceptar las condiciones', 'warning'); */
      return;
    }

   /*  let usuario = new Usuario(
      this.forma.value.cod,
      this.forma.value.nombreUsuario,
      this.forma.value.username,
      this.forma.value.password,
      this.forma.value.rol
    ); */

  /*   this._loginService.crearUsuario( usuario )
              .subscribe( resp => this.router.navigate(['/login'])); */
  }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {
      // obtener valores
      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;
      // si esta todo correcto
      if ( pass1 === pass2 ) {
        return null;
      }
       // si no
      return {
        sonIguales: true
      };

    };

  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../config/config';

import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Empresa } from '../../models/empresa.model';
import { UsuarioService } from '../usuario/usuario.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { _throw as throwError } from 'rxjs/observable/throw';
import swal from 'sweetalert2';
import { LoginService } from '../login/login.service';




@Injectable()
export class EmpresaService {
  private urlEndPoint: string = BASE_URL;
  empresa: Empresa;

  headers: HttpHeaders; // new HttpHeaders();
  headersImagen: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _loginService: LoginService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this._loginService.token
    });
  }

  getById(id: number) {
    let url = BASE_URL + 'empresas/' + id;
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }
  getEmpresas() {
    let url = BASE_URL + 'empresas';
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }
  getImage(nombre: string) {
    let url = BASE_URL + 'empresas/download-image/' + nombre;
    return this.http.get(url, { headers: this.headers, responseType: 'blob' });
    // .map( data => data);
  }
   update(empresa: Empresa): Observable<Empresa> {
    return this.http.put<any>(BASE_URL + 'empresas', empresa, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getEmpresa(id): Observable<Empresa> {
    return this.http.get<Empresa>(`${BASE_URL}empresas/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        this.router.navigate(['/empresas']);
        console.error(e.error.mensaje);
        swal.fire('Error al editar', e.error.mensaje, 'error');
        return throwError(e);
      })
    );
  }

}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://apptest.mobile.com.py:8083/api/'
  //baseUrl: 'https://mchatdev.mobile.com.py/mchatbot/api/',
  // baseUrl: 'http://localhost:8083/api/'
  baseUrl: 'https://mcontroldev.mobile.com.py/control-reparto-sur/api/',
  requestUrl: 'https://mcontroldev.mobile.com.py/control-reparto-sur/api/'
};

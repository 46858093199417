import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EmpresaComponent } from './empresa/empresa.component';
// import { EmpresaDetalleComponent } from './empresas/empresa-detalle/empresa-detalle.component';
import { FormUsuarioComponent } from './formularios/form-usuario.component';
import { LoginGuardGuard, VerificaTokenGuard } from '../services/service.index';
import { ParametrosComponent } from './parametros/parametros.component';
import { FormParametrosComponent } from './formularios/form-parametros.component';
import { RepartoComponent} from './control-reparto/reparto.component';
import { FacturaComponent } from './control-reparto/factura.component';
import { EstadosComponent } from './control-reparto/estados.component';

const pagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuardGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        canActivate: [VerificaTokenGuard],
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Dashboard' }
      },
      {
        path: 'estados',
        component: EstadosComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Estados' }
      },
      {
        path: 'reparto',
        component: RepartoComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Reparto' }
      },
      {
        path: 'reparto/:grupo',
        component: RepartoComponent,
        canActivate: [VerificaTokenGuard],
        data:
            { titulo: 'Reparto' }
      },
      {
        path: 'facturas',
        component: FacturaComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Facturas' }
      },
      {
        path: 'facturas/:grupo',
        component: FacturaComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Facturas' }
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Usuarios' }
      },
      {
        path: 'usuarios/page/:page',
        component: UsuariosComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Usuarios' }
      },
      {
        path: 'usuarios/formulario',
        component: FormUsuarioComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Usuarios Detalle' }
      },
      {
        path: 'usuarios/formulario/:codusuario',
        component: FormUsuarioComponent,
        canActivate: [VerificaTokenGuard],
        data: { titulo: 'Usuarios Detalle' }
      },
      /*{
        path: 'empresa',
        component: EmpresaComponent,
        canActivate: [VerificaTokenGuard],

        data: { titulo: 'Empresa' }
      },*/
      {
        path: 'account-settings',
        component: AccoutSettingsComponent,
        canActivate: [VerificaTokenGuard],

        data: { titulo: 'Ajustes de Tema' }
      }
    ]
  }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { EstadoEmpaques } from '../../models/estadoEmpaques.model';
import {EstadoEmpaqueService, SucursalService} from '../../services/service.index';
import Swal from 'sweetalert2';
import { RepartoService } from '../../services/reparto/reparto.service';
import { Reparto } from '../../models/reparto.model';
import { RepartoHead } from '../../models/repartoHead.model';
import { Sucursal } from '../../models/sucursal.model';

@Component({
  selector: 'app-estados',
  templateUrl: './estados.component.html',
  styles: []
})
export class EstadosComponent implements OnInit {
  //repartos: any[];
  estadosEmpaque: EstadoEmpaques[];
  estados: any[];
  selectEstado: string;
  user: any;
  enableEstado: boolean;
  enableSucursal: boolean;
  fechaDesde: string;
  fechaHasta: string;
  cargando: boolean = false;
  buscar: boolean;
  userRol: string;
  sucursales: Sucursal[];
  selectSucursal: Sucursal;
  //desconfirmarDisable: boolean;


  constructor(public router: Router, public _estadoEmpaqueService: EstadoEmpaqueService, 
    public _repartoService: RepartoService,
    public _sucursalService: SucursalService) { }

  ngOnInit() {
    this.estadosEmpaque = [];
    this.buscar = false;
    this.fechaDesde = moment(new Date()).format('YYYY-MM-DD');//moment(new Date('2020-01-16')).format('YYYY-MM-DD');
    this.fechaHasta = moment(new Date()).format('YYYY-MM-DD');//moment(new Date('2020-01-16')).format('YYYY-MM-DD');
    this.selectEstado = 'PENDIENTE';
    this.estados = [
      {id: 'PENDIENTE', descripcion: 'PENDIENTE'},
      {id: 'CONFIRM_FACTU', descripcion: 'CONFIRMADO POR FACTURACION'},
      {id: 'CONFIRM_DEP', descripcion: 'CONFIRMADO POR DEPOSITO'},
      {id: 'CONFIRM_CHOFER', descripcion: 'CONFIRMADO POR CHOFER'},
      {id: 'FINALIZADO', descripcion: 'FINALIZADO'}
    ];
    if (localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userRol = this.user.authorities[0];
      //console.log(this.user);
      this.selectSucursal = this.user.codSucursal;
      //console.log(this.user);
    }
    
    if (this.userRol === 'ROLE_ADMIN' || this.userRol === 'ROLE_FACTURADOR'){
      this.enableEstado = true;
    } else {
      this.enableEstado = false;
      //si es rol depositero el estado debe ser CONFIRM_FACTU
      if (this.userRol === 'ROLE_DEPOSITERO')
        this.selectEstado = 'CONFIRM_FACTU';
      else
        //si es rol chofer el estado debe ser CONFIRM_DEP
        this.selectEstado = 'CONFIRM_DEP';
    }

    if (this.userRol === 'ROLE_ADMIN') {
      this.enableSucursal = true;
      this._sucursalService.getSucursales(this.user.codEmpresa)
      .subscribe((resp: Sucursal[]) => {
        this.sucursales = resp;
      });
    }else{
       this.enableSucursal = false;
    }

  }

  buscarReparto(){
    //buscar en función del rol, si es admin utilizar el valor de la sucursal del select
    let codSucursal;
    if (this.selectSucursal)
      codSucursal = this.selectSucursal;
    else
      codSucursal = this.user.codSucursal;

    this._estadoEmpaqueService.getEstadosEmpaques(this.selectEstado, this.fechaDesde, this.fechaHasta, this.user.codEmpresa, codSucursal)
      .subscribe((resp: EstadoEmpaques[]) => {
      this.estadosEmpaque = resp;
    });
  }

  selectedItem(item){
    this.selectEstado = item.id;
  }

  confirmar(item: EstadoEmpaques){
    //hay que buscar si en el array existe otro item con el mismo grupo de reparto y confirmar
    //pero antes de confimar, mostrar un modal que indique la cantidad de grupo de repartos a confirmar
    //item.estado = 'CONFIRM_FACTU';
    let cant = 0;
    let auxEstadosEmpaque: EstadoEmpaques[] = [];
    //auxEstadosEmpaque.push(item);
    for(let i=0; i<this.estadosEmpaque.length; i++){
      if(this.estadosEmpaque[i].grupoReparto === item.grupoReparto){
        this.estadosEmpaque[i].estado = 'CONFIRM_FACTU';
        auxEstadosEmpaque.push(this.estadosEmpaque[i]);
        cant++;
      }
    }
    if (cant > 0){
      Swal.fire({
        title: 'Atención?',
        text: `Se confirmará ${cant} grupo de facturas del grupo de reparto ${item.grupoReparto}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'btn btn-outline-info',
        cancelButtonText: 'Cancelar',
        cancelButtonClass: 'btn btn-outline-secondary',
        buttonsStyling: true,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this._estadoEmpaqueService.postEstadosEmpaques(auxEstadosEmpaque)
            .subscribe(
              repar => {
                Swal.fire('Excelente', `La operación ha finalizado con éxito`, 'success');
                this.buscarReparto();
              },
              err => {
                Swal.fire(err.error.error, err.error.message, 'error');
              }
            );
          }else{
            this.buscarReparto();
          }
      });
    }
  }

  desconfirmar(item: EstadoEmpaques){
    //tiene que verificar primeramente si ese grupo de reparto no fue aún iniciado por el depositero
    //si todavía no se inició puede confirmar, si ya inció mostrar alerta
    let repartoHead: RepartoHead;
    let auxEstadosEmpaque: EstadoEmpaques[] = [];
    let cant = 0;
    this._repartoService.getReparto(item.grupoReparto, this.user.codEmpresa, this.user.codSucursal).subscribe(
      resp => { 
        repartoHead = resp['head']; 
        //verificar si aún no se inicio del control de depósito
        if (repartoHead.inicioDeposito === null || repartoHead.inicioDeposito === false){
          //recorrer el array e ir desconfirmando todos los grupos de factura pertenecientes al grupo de reparto seleccionado
          for(let i=0; i<this.estadosEmpaque.length; i++){
            if(this.estadosEmpaque[i].grupoReparto === item.grupoReparto){
              this.estadosEmpaque[i].estado = 'PENDIENTE';
              auxEstadosEmpaque.push(this.estadosEmpaque[i]);
              cant++;
            }
          }
          if (cant > 0){
            Swal.fire({
              title: 'Atención?',
              text: `Se desconfirmará ${cant} grupo de facturas del grupo de reparto ${item.grupoReparto}`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Aceptar',
              confirmButtonClass: 'btn btn-outline-info',
              cancelButtonText: 'Cancelar',
              cancelButtonClass: 'btn btn-outline-secondary',
              buttonsStyling: true,
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                //actualizar la tabla de estados, colocar estado = PENDIENTE en vez de estado = CONFIRM_FACTU
                this._estadoEmpaqueService.putEstadosEmpaques(auxEstadosEmpaque).subscribe(
                  repar => {
                    //si hizo todo bien, mostrar mensaje de éxito
                    Swal.fire('Excelente', `La operación ha finalizado con éxito`, 'success');
                    this.buscarReparto();
                  },
                  err => {
                    //si algo ocurrió mal, mostrar mensaje de error
                    Swal.fire(err.error.error, err.error.message, 'error');
                  }
                );
              }else{
                //para que deje todo como estaba
                this.buscarReparto();
              }
            });
          }
        }else{
          //si no es null, es porque ya se inició por el depositero. Mostrar alerta
          Swal.fire('Atención', `El grupo de reparto ya fue inicido por el control de depósito`, 'warning');
        }
      }, 
      //si algo ocurrió mal al buscar el reparto, mostrar mensaje de error
      err => { Swal.fire(err.error.error, err.error.message, 'error'); }
    );
  }

  gotolink(item: EstadoEmpaques){
    if(item.estado === 'CONFIRM_FACTU' && this.userRol === 'ROLE_DEPOSITERO')
      this.router.navigate(['reparto/', item.grupoReparto]);
      if(item.estado === 'CONFIRM_DEP' && this.userRol === 'ROLE_CHOFER')
      this.router.navigate(['facturas/', item.grupoReparto]);
  }
}

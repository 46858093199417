import { Parametro } from "./../../models/parametro.model";

import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError
} from "rxjs/operators";
import {
  OnInit,
  Input,
  Output,
  Component,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { Observable, of } from "rxjs";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ParametroService, LoginService } from '../../services/service.index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "ng-select-parametros",
  templateUrl: "./ng-select-parametros.component.html",
  styles: [``]
})
export class NGSelectParametrosComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() cargadorParametros: Parametro;
  @Output("retornoObjeto") retornoObjeto: EventEmitter<
    Parametro
  > = new EventEmitter();
  searching: boolean = false;
  searchFailed: boolean = false;
  cargando: boolean = false;
  parametros: Parametro[] = [];
  model: any;
  nombre: string;

  ngOnInit() {
    this.cargarParametros();
  }

  constructor(
    public _parametroService: ParametroService,
    public _loginService: LoginService
  ) {}

  cargarParametros() {
    this.cargando = true;
    this._parametroService
      .get(this._loginService.user.idEmpresa)
      .subscribe(response => {
        console.log(response)
        this.parametros = response as Parametro[];
        this.cargando = false;
      });
  }

  selectedItem(item) {
    this.cargadorParametros = item;
  }

  agregar() {
    this.retornoObjeto.emit(this.cargadorParametros);
    this.ngSelectComponent.handleClearClick();
  }
}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { BASE_URL } from '../../config/config';
import { tap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { NumeroEmpresa } from '../../models/numeroEmpresa.model';


@Injectable()
export class NumeroEmpresaService {

  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _loginService: LoginService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this._loginService.token
    });
  }

  getNumbersForChat(idEmpresa: string) {
    let url = BASE_URL + 'numerosempresa/chat' + '?idEmpresa=' + idEmpresa;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  update(num: NumeroEmpresa): Observable<NumeroEmpresa> {
    return this.http.put<any>(BASE_URL + 'numerosempresa', num, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  create(num: NumeroEmpresa): Observable<NumeroEmpresa> {
  return this.http.post(BASE_URL + 'numerosempresa', num, { headers: this.headers} )
    .pipe(
      map((response: any) => response),
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
}

}

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalUploadService } from '../components/modal-upload/modal-upload.service';

import {
  ParametroService,
  UsuarioService,
  EmpresaService,
  SettingsService,
  SidebarService,
  LayoutService,
  LoginGuardGuard,
  SubirArchivoService,
  DashboardService,
  ModalService,
  LoginService,
  VerificaTokenGuard,
  RolService,
  NumeroEmpresaService,
  SucursalService,
  DataCopyService,
  RepartoService,
  EstadoEmpaqueService,
  PushNotificationService
 } from './service.index';




@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ParametroService,
    UsuarioService,
    EmpresaService,
    LoginService,
    DashboardService,
    LayoutService,
    SettingsService,
    SidebarService,
    SubirArchivoService,
    LoginGuardGuard,
    ModalUploadService,
    ModalService,
    VerificaTokenGuard,
    RolService,
    NumeroEmpresaService,
    SucursalService,
    DataCopyService,
    RepartoService,
    EstadoEmpaqueService,
    PushNotificationService
  ],
  declarations: []
})
export class ServiceModule { }

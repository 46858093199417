
import {Observable} from 'rxjs/Rx';
import {Component, Input, Output, ElementRef, EventEmitter} from '@angular/core';

@Component({
    selector: 'input-debounceComponent',
    template: `
    <div class="input-group" >
            <input type="text"
                   id="inputDebounce"
                   class="form-control  "
                   width="100%"[placeholder]="placeholder"
                   [(ngModel)]="inputValue">

                   </div>
                   `
                })
                /*             <div class="input-group-addon btn-secundary" disabled="false" ><i class="fa fa-search"></i></div>
                 */
export class InputDebounceComponent {
    @Input() placeholder: string;
    @Input() delay: number = 500;
    @Output() value: EventEmitter<any> = new EventEmitter();

    public inputValue: string;

    constructor(private elementRef: ElementRef) {
        const eventStream = Observable.fromEvent(elementRef.nativeElement, 'keyup')
            .map(() => this.inputValue)
            .debounceTime(this.delay);
/*             .distinctUntilChanged();
 */
        eventStream.subscribe(input => this.value.emit(input));
    }
}

import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/service.index';
import { User } from '../models/user.model';
import { LoginService } from '../services/login/login.service';

declare function init_plugins();
declare const gapi: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  recuerdame: boolean = false;
  auth2: any;

  constructor(
    public router: Router,
    public _loginService: LoginService
  ) { }

  ngOnInit() {
    init_plugins();
  /*   this.googleInit(); */

    this.username = localStorage.getItem('username') || '';
    if ( this.username.length > 1 ) {
      this.recuerdame = true;
    }

  }
/*
  googleInit() {

    gapi.load('auth2', () => {

      this.auth2 = gapi.auth2.init({
        client_id: '442737206823-dilej5tevnrv61sovd7bocf5qeafmjs3.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });

      this.attachSignin( document.getElementById('btnGoogle') );

    });

  } */

/*   attachSignin( element ) {

    this.auth2.attachClickHandler( element, {}, (googleUser) => {

      // let profile = googleUser.getBasicProfile();
      let token = googleUser.getAuthResponse().id_token;

      this._loginService.loginGoogle( token )
              .subscribe( () => window.location.href = '#/dashboard'  );

    });

  } */


  ingresar( ngForm: NgForm) {
    if ( ngForm.invalid ) {
      return;
    }
    let user = new User(null, null, null,null, ngForm.value.username, ngForm.value.password, null , null);
    this._loginService.login(user, ngForm.value.recuerdame )
          .subscribe( correcto => {
            this.router.navigate(['/dashboard']);
          }  );
  }

}

import { environment } from '../../environments/environment';


// LOCAL
// export const BASE_URL = 'http://localhost:8083/api/';

// TEST
// export const BASE_URL = 'http://apptest.mobile.com.py:8083/api/';

// PRD
// export const BASE_URL = 'https://mchat.mobile.com.py/mchatbot/api/';

export const BASE_URL = environment.baseUrl;
export const REQUEST_URL = environment.requestUrl;
import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../models/empresa.model';
import * as moment from 'moment';
import { EmpresaService, UsuarioService, ModalService, LoginService, NumeroEmpresaService } from '../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { } from '../../config/config';
import { NumeroEmpresa } from '../../models/numeroEmpresa.model';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { log } from 'util';
@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styles: []
})
export class EmpresaComponent implements OnInit {
  sinImagen: string = './assets/images/sin-imagen.jpg';
  // empresas: Empresa[] = [];
  empresa: Empresa;
  imagen: null;
  numero: NumeroEmpresa = new NumeroEmpresa();
  editable: boolean = false;
  constructor(public _empresaService: EmpresaService,
    private activatedRoute: ActivatedRoute,
    private _loginService: LoginService,
    private toastr: ToastrService,
    private _numeroEmpresaService: NumeroEmpresaService) { }

  ngOnInit() {
    this.getEmpresa(this._loginService.user.idEmpresa);
  }

  getEmpresa(idEmpresa) {
    this._empresaService.getById(idEmpresa).subscribe((resp: any) => {
      // console.log(resp);
      this.empresa = resp;
      // let auxHourInicio = this.empresa.inicioEnvio.split(" ")[1];
      // let auxHourFin = this.empresa.finEnvio.split(" ")[1];
      // this.empresa.inicioEnvio = auxHourInicio;
      // this.empresa.finEnvio = auxHourFin;
      console.log(this.empresa);
      // this.empresas.push(this.empresa);
      //  console.log(resp[0].img);
    });
  }

  toggleForm() {
    if (this.editable) {
      this.editable = false;
    } else {
      this.editable = true;
    }
  }
  editItem(item?: NumeroEmpresa) {
    if (item) {
      this.numero = item;
    } else {
      this.numero = new NumeroEmpresa();
    }
  }
  update(): void {
    let auxHourInicio = moment(this.empresa.inicioEnvio, 'HH:mm').format('HH:mm:ss');
    let auxHourFin = moment(this.empresa.finEnvio, 'HH:mm').format('HH:mm:ss');
    this.empresa.inicioEnvio = auxHourInicio;
    this.empresa.finEnvio = auxHourFin;
    console.log(this.empresa);
    this._empresaService.update(this.empresa)
      .subscribe(
        resp => {
          swal.fire('Empresa Actualizada', `${resp.razonSocial}`, 'success');
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
  }

  editNumEmpresa(num: NumeroEmpresa) {
    if (num.id) {
      this._numeroEmpresaService.update(num).subscribe(
        resp => {
          swal.fire('Número Actualizado', `telefono  : ${resp.telefono}`, 'success');
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
    } else {
      num.idEmpresa = this._loginService.user.idEmpresa;
      this._numeroEmpresaService.create(num).subscribe(
        resp => {
          this.empresa.numeros.push(resp);
          // swal.fire('Número Agregado a la Empresa', `Número  : ${resp.telefono}`, 'success');
          this.update();
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
    }
  }

  cancelar() {
    this.ngOnInit();
    this.editable = false;
  }
  error(err) {
    this.toastr.error(err, 'Error',
      { timeOut: 2500 });
  }

}

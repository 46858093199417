import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_URL,REQUEST_URL } from '../../config/config';
import { Reparto } from '../../models/reparto.model';
import { _throw as throwError } from 'rxjs/observable/throw';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class RepartoService {
  reparto: Reparto[] = [];
  constructor(public http: HttpClient, public router: Router) { }
  
  getReparto(/*grupoFactura: string,*/ grupoReparto: number, codEmpresa: number, codSucursal: number) {
    return this.http.get(REQUEST_URL + 'empaques?grupoReparto=' + grupoReparto + '&codempresa=' + codEmpresa + '&codsucursal=' + codSucursal )
    //return this.http.get(REQUEST_URL + 'empaque?grupoFactura=' + grupoFactura)
    .map((response: Reparto) => {
      return response;
    });
  }

  /*postReparto(reparto: Reparto): Observable<Reparto> {
    return this.http.post(BASE_URL + 'empaques', reparto)
      .pipe(
        map((response: any) => response.reparto as Reparto),
        catchError(e => {
          return throwError(e);
        })
      );
  }*/

  postReparto(reparto: Reparto): Observable<Reparto> {
    return this.http.post<Reparto>(BASE_URL + 'empaques', reparto)
    .pipe(
      catchError(e => throwError(e))
    );
  }

  /*putReparto(reparto: Reparto): Observable<Reparto> {
    return this.http.put(BASE_URL + 'empaques', reparto)
      .pipe(
        map((response: any) => response.reparto as Reparto),
        catchError(e => {
          //console.error('ERROR', e.error);
          //Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }*/
  putReparto(reparto: Reparto): Observable<Reparto> {
    return this.http.put<Reparto>(BASE_URL + 'empaques', reparto)
    .pipe(
      catchError(e => throwError(e))
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { SidebarService, UsuarioService, EmpresaService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { Empresa } from '../../models/empresa.model';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  user: User;
  empresa: Empresa ;
  imagen: string = './assets/images/users/srPool.jpg';
  sinImagen: string = './assets/images/users/user_img.png';
  imagenEmpresa: string = './assets/images/logo-text.png';
  constructor(
    public _loginService: LoginService,
     public _usuarioService: UsuarioService,
    public _empresasService: EmpresaService ) { }

  ngOnInit() {

   /*  this.user = this._loginService.user;
    this.cargar();
    setTimeout(() => {
      this.cargar();
    }, 2000); */
}

 cargar() {
  this._usuarioService.getUsuarioByIdForImg(this.user.idUsuario).subscribe(us => {
    this.user.img = us.img;
        this._empresasService.getEmpresa(this.user.idEmpresa).subscribe(em => {
        this.empresa = em;
        });
    });
}

}

import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../config/config';
import 'rxjs/add/operator/map';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoginService {
  user: User;
  headers: HttpHeaders; // new HttpHeaders();
  headersComponent: HttpHeaders; // new HttpHeaders();
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    private toastr: ToastrService,
    public _subirArchivoService: SubirArchivoService
  ) {
    this.cargarStorage();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
  }

  // renuevaToken() {
  //   let url = BASE_URL + 'auth/login';
  //   let body  = JSON.stringify({ username : this.user.sub, password : this.user.password });
  //   return this.http.post( url, body)
  //   .map((response: any) => {
  //     let decode = [];
  //     decode = response.token.split('.');
  //     let usu: any;
  //     let ini;
  //     let exp;
  //     usu = JSON.parse(window.atob(decode[1]));
  //       ini = new Date(usu.iat * 1000);
  //       exp = new Date(usu.exp * 1000);
  //     console.log('inicio:' + ini);
  //     console.log('expira:' + exp);
  //     this.guardarLocalStorage(response.token, this.user);
  //     console.log('tokenRenovado:');
  //     return true;
  //   })
  //   .catch( err => {
  //     console.error(err)
  //     this.router.navigate(['/login']);
  //     Swal.fire('No se pudo renovar token', 'No fue posible renovar token', 'error');
  //     return Observable.throw(err);
  //   });
  // }

  renuevaToken() {
    return this.http.get(BASE_URL + 'auth/token').pipe(
      map((response: any) => {

        let decode = [];
        decode = response.token.split('.');
        let usu: any;
        let ini;
        let exp;
        usu = JSON.parse(window.atob(decode[1]));
        ini = new Date(usu.iat * 1000);
        exp = new Date(usu.exp * 1000);
        localStorage.removeItem('token');
        this.guardarLocalStorage(response.token, this.user);
        return true;
      }),
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  estaLogueado() {
    return this.token.length > 5 ? true : false;
  }

  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
    } else {
      this.token = '';
      this.user = null;
    }
  }

  guardarStorage(id: string, token: string, user: User) {
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
    this.token = token;
  }

  guardarLocalStorage(token: string, user: User) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
    this.token = token;
  }

  logout() {
    this.user = null;
    this.token = '';

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  loginGoogle(token: string) {
    let url = BASE_URL + '/login/google';

    return this.http.post(url, { token }).map((resp: any) => {
      this.guardarStorage(resp.id, resp.token, resp.usuario);
      return true;
    });
  }

  login(user: User, recordar: boolean = false) {
    if (recordar) {
      localStorage.setItem('username', user.sub);
    } else {
      localStorage.removeItem('username');
    }
    let body = JSON.stringify({ username: user.sub, password: user.password });
    let url = BASE_URL + 'auth/login';

    return this.http.post(url, body).pipe(
      map((response: any) => {
        /*     let t = JSON.parse(JSON.stringify(response));
            console.log(t.token); */
        let decode = [];
        decode = response.token.split('.');
        let usu: any;
        let ini;
        let exp;
        usu = JSON.parse(window.atob(decode[1]));
        user.idUsuario = usu.idUsuario;
        user.idEmpresa = usu.idEmpresa;
        user.sub = usu.sub;
        user.authorities = usu.authorities;
        user.pin = usu.pin;
        user.codEmpresa = usu.codEmpresa;
        user.codSucursal = usu.codSucursal;
        ini = new Date(usu.iat * 1000);
        exp = new Date(usu.exp * 1000);
        this.guardarLocalStorage(response.token, user);
        return true;
      }),
      catchError(e => {
        this.invalido(
          'Verifique que su usuario y contraseña sean correctas!! '
        );
        console.error('ERROR', e.error);
        if (e.error.header) {
          Swal.fire(e.error.header, e.error.message, 'error');
        }
        return throwError(e);
      })
    );
  }

  invalido(m) {
    this.toastr.error(m, 'No Autorizado', { timeOut: 3000 });
  }
}

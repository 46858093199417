import { NgModule } from '@angular/core';
import { PAGES_ROUTES } from './pages.routes';
import { FormsModule } from '@angular/forms';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { LayoutModule } from '../layout/layout.module';
import { PipesModule } from '../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModalUploadComponent } from '../components/modal-upload/modal-upload.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { EmpresaDetalleComponent } from './empresa/empresa-detalle/empresa-detalle.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { PaginatorsComponent } from '../components/paginators/paginators.component';
import { NgbdTypeaheadConfig } from '../components/typeahead-config/typeahead-config';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdTypeaheadTemplate } from '../components/typeahead-template/typeahead-template';
import { NgbdTypeaheadHttp } from '../components/typeahead-http/typeahead-http';
import { InputDebounceComponent } from '../components/inputDebounce/inputDebounce.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormUsuarioComponent } from './formularios/form-usuario.component';
import { TextMaskModule } from 'angular2-text-mask';
import {
  NgbPaginationModule,
  NgbAlertModule
} from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from '../components/search/search';
import { NgxPrintModule } from 'ngx-print';
import { ParametrosComponent } from './parametros/parametros.component';
import { FormParametrosComponent } from './formularios/form-parametros.component';
import { ChartsModule } from 'ng2-charts';
import { BubbleChartComponent } from '../components/bubble-chart/bubble-chart.component';
import { DoughnutChartComponent } from '../components/doughnut-chart/doughnut-chart.component';
import { Line2ChartComponent } from '../components/line2-chart/line2-chart.component';
import { LineChartComponent } from '../components/line-chart/line-chart.component';
import { PieChartComponent } from '../components/pie-chart/pie-chart.component';
import { PolarAreaChartComponent } from '../components/polar-area-chart/polar-area-chart.component';
import { RadarChartComponent } from '../components/radar-chart/radar-chart.component';
import { BarChartComponent } from '../components/bar-chart/bar-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TypeaheadModule } from 'ngx-type-ahead';
import { NGSelectParametrosComponent } from './../components/select-parametros/ng-select-parametros';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import { BusquedaRepartoComponent } from '../components/busqueda-reparto/busqueda-reparto.component';
import { RepartoComponent } from './control-reparto/reparto.component';
import { FacturaComponent } from './control-reparto/factura.component';
import { EstadosComponent } from './control-reparto/estados.component';
import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

/*import {  ScrollingModule  } from '@angular/cdk/scrolling';
import { TableModule } from 'primeng/table';*/


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    PagesComponent,
    SearchComponent,
    DashboardComponent,
    InputDebounceComponent,
    AccoutSettingsComponent,
    NgbdTypeaheadHttp,
    NgbdTypeaheadConfig,
    NgbdTypeaheadTemplate,
    UsuariosComponent,
    ModalUploadComponent,
    EmpresaComponent,
    EmpresaDetalleComponent,
    ParametrosComponent,
    FormParametrosComponent,
    PaginatorComponent,
    PaginatorsComponent,
    FormUsuarioComponent,
    BubbleChartComponent,
    DoughnutChartComponent,
    Line2ChartComponent,
    LineChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    RadarChartComponent,
    BarChartComponent,
    NGSelectParametrosComponent,
    BusquedaRepartoComponent,
    RepartoComponent,
    FacturaComponent,
    EstadosComponent
  ],
  exports: [DashboardComponent],
  imports: [
    CommonModule,
    LayoutModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    TextMaskModule,
    NgxPrintModule,
    PAGES_ROUTES,
    PerfectScrollbarModule,
    FormsModule,
    PipesModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    ChartsModule,
    TypeaheadModule,
    NgSelectModule,
    NgxDatatableModule,
    NgxPaginationModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }]
})
export class PagesModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ObjetoSelector } from '../../models/objetoSelector.model';

@Component({
  selector: 'app-busqueda-reparto',
  templateUrl: './busqueda-reparto.component.html',
  styles: []
})
export class BusquedaRepartoComponent implements OnInit {

  grupoReparto: string;
  grupoFactura: string;
  size: number;
  public numeros: ObjetoSelector[] = [
    { cod: 10, descripcion: '10', enum: '10' },
    { cod: 15, descripcion: '15', enum: '15' },
    { cod: 20, descripcion: '20', enum: '20' },
    { cod: 25, descripcion: '25', enum: '25' },
    { cod: 30, descripcion: '30', enum: '30' },
    { cod: 40, descripcion: '40', enum: '40' },
    { cod: 50, descripcion: '50', enum: '50' },
    { cod: 100, descripcion: '100', enum: '100' }
  ];
  repartos: any[] = []; // cmabiar mas adelante por model de Repartos
  cargando: boolean = false;
  totalElementos: number = 0;
  tamanhoPag: string = 'sm';
  ellipses: boolean = false;
  cantidadElementos: number = 0;
  pagina: number = 0;
  paginador: any;
  rutaPaginador: string = '/reparto/page/';
  public router: Router

  constructor() { }

  ngOnInit() {
    this.cargando = true;
    this.grupoReparto = null;
    this.grupoFactura = null;
    this.size = 20;
    this.cargando = false;
  }

  buscarReparto() {
    console.log(this.grupoFactura);
    console.log(this.grupoReparto);
  }

  cambioNumero(EVENTO) {
    this.size = EVENTO;
  }

  loadPage(page: number) {
    if (page !== this.paginador) {
      this.paginador = page - 1;
      this.router.navigate([this.rutaPaginador, this.paginador]);
    }
  }
}

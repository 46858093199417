
export class Parametro {
    //  ? significa opcional
    public id: string;
    public clave: string;
    public valor: string;
    public descripcion: string;
    public system: boolean;
    public idEmpresa: string;
    public class: string;

    constructor() { }
}

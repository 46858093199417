import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL, REQUEST_URL} from '../../config/config';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';
import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario.model';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { _throw as throwError } from 'rxjs/observable/throw';
import { LoginService } from '../login/login.service';
@Injectable()
export class UsuarioService {

  user: User;
  headers: HttpHeaders; // new HttpHeaders()
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _loginService: LoginService,
    public _subirArchivoService: SubirArchivoService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._loginService.token
    });
  }


  getUsuariospaginados(idEmpresa: string, page: number, termino: string) {
    let url = '';
    if (termino === '') {
      url = BASE_URL + 'usuarios?idEmpresa=' + idEmpresa + '&page=' + page + '&size=10';
    } else {
      url = BASE_URL + 'usuarios?idEmpresa=' + idEmpresa + '&size=10&page=' + page + '&keyword=' + termino;
    }
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => response);
  }

  getUsuariosAll() {
    return this.http.get(REQUEST_URL + 'usuarios')
    .map((response: any) => response);
  }

  uploadImage(imagen, code): Observable<any> {
    this.headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let formData: FormData = new FormData();
    formData.append('id', code);
    formData.append('image', imagen);
    let url = BASE_URL + 'usuarios/upload-image';
    return this.http.post(url, formData, { headers: this.headers })
      .map((response: any) => {
        console.log(response);
        return response;
      });

  }

  /*
    logout() {
      this.user = null;
      this.token = '';

      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      this.router.navigate(['/login']);
    }

    loginGoogle( token: string ) {

      let url =  + '/login/google';

      return this.http.post( url, { token } )
                  .map( (resp: any) => {
                    this.guardarStorage( resp.id, resp.token, resp.usuario );
                    return true;
                  });


    }


    login( usuario: User, recordar: boolean = false ) {

      if ( recordar ) {
        localStorage.setItem('username', usuario.username );
      } else {
        localStorage.removeItem('username');
      }
      let body  = JSON.stringify({ username : usuario.username, password : usuario.password });
      let url = BASE_URL + 'auth/login';

      return this.http.post( url, body)
      .map((response: any) => {

        let decode = [];
        decode = response.token.split('.');
        let usu: any;
        let ini;
        let exp;
        usu = JSON.parse(window.atob(decode[1]));
          usuario.codUsuario = usu.codUsuario;
          usuario.codEmpresa = usu.codEmpresa;
          usuario.nombreUsuario = usu.nombreUsuario;
          usuario.username = usu.sub;
          usuario.authorities = usu.authorities;
          usuario.img = '';
          ini = new Date(usu.iat * 1000);
          exp = new Date(usu.exp * 1000);
          this.guardarLocalStorage(response.token, usuario);
        return true;
      });

    } */

  traerEmpresasPorId(id: number) {
    let url = BASE_URL + 'empresas/' + id;
    console.log(url, { headers: this.headers });
    return this.http.get(url, { headers: this.headers })
      .map((resp: any) => resp);
  }


  getById(id): Observable<Usuario> {
    return this.http.get<Usuario>(BASE_URL + 'usuarios' + `/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        // this.router.navigate(['/usuarios']);
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getUsuarioByIdForImg(id): Observable<Usuario> {
    return this.http.get<Usuario>(BASE_URL + 'usuarios' + `/${id}`, { headers: this.headers }).pipe(
      tap((response: any) => response as Usuario)
    );
  }

  create(usuario: Usuario) {
    return this.http.post<any>(BASE_URL + 'usuarios', usuario, { headers: this.headers })
      .pipe(
        tap((response: any) => response.usuario as Usuario),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }

  update(usuario: Usuario): Observable<any> {
    return this.http.put<any>(BASE_URL + 'usuarios', usuario, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete<Usuario>(BASE_URL + 'usuarios' + `/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }
}

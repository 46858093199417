import { Pipe, PipeTransform } from '@angular/core';
import { BASE_URL } from '../config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UsuarioService } from '../services/service.index';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  headers: HttpHeaders;
  constructor(
    public http: HttpClient,
    public _loginServices: LoginService,
    private sanitizer: DomSanitizer
   ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':  'Bearer ' + this._loginServices.token
    });
  }

  transform( img: string, tipo: string): Observable<SafeUrl> {
    let url = BASE_URL + tipo + '/download-image/' + img;

      return this.http.get(url, { headers: this.headers, responseType: 'blob'})
       .map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)));
  }

}


import { Rol } from './rol.model';
import {Sucursal} from './sucursal.model';

export class Usuario {
  //  ? significa opcional
  // public codUsuario: number;
  // public nombrePersona: string;
  public codUsuario?: string;
  public nombrePersona: string;
  public codPersonaErp?: string;
  public username: string;
  public rol: Rol;
  public password?: string;
  public codSucursal?: string;
  public codEmpresa: string;
  public createdAt?: string;
  public modifiedAt?: string;
  public lastLoginAt?: string;
  public createdBy?: string;
  public modifiedBy?: string;
  public enabled: boolean;
  // public sucursal: any;
  public pin?: string;
  public img?: string;
  constructor() { }

}


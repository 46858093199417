 export class NumeroEmpresa {
      public id: string;
      public idEmpresa: string;
      public descripcion: string;
      public telefono: string;
      public instancia?: string;
      public whatsappUrl?: string;
      public whatsappToken?: string;
      public forChatBot: boolean;
    constructor() { }

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { BASE_URL } from '../../config/config';
import { tap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';


@Injectable()
export class SucursalService {

  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

  headers: HttpHeaders;

  constructor(
              public http: HttpClient,
              public router: Router,
              public _loginService: LoginService
             ) {
              this.headers = new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + this._loginService.token
              });
            }

  getSucursales(idEmpresa: string) {
    let url = BASE_URL + 'sucursales?idEmpresa=' + idEmpresa;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

}

import { Component, OnInit, HostListener } from '@angular/core';
import { ObjetoSelector } from '../../models/objetoSelector.model';
import { Reparto } from '../../models/reparto.model';
import { RepartoHead } from '../../models/repartoHead.model';
import { RepartoDet } from '../../models/repartoDet.model';
import { RepartoFoot } from '../../models/repartoFoot.model';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';
import { Sucursal } from '../../models/sucursal.model';
import { RepartoService, SucursalService } from '../../services/service.index';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styles: []
})
export class FacturaComponent implements OnInit {
  grupoReparto: number;
  //grupoFactura: string;
  size: number;
  public numeros: ObjetoSelector[] = [
    { cod: 10, descripcion: '10', enum: '10' },
    { cod: 15, descripcion: '15', enum: '15' },
    { cod: 20, descripcion: '20', enum: '20' },
    { cod: 25, descripcion: '25', enum: '25' },
    { cod: 30, descripcion: '30', enum: '30' },
    { cod: 40, descripcion: '40', enum: '40' },
    { cod: 50, descripcion: '50', enum: '50' },
    { cod: 100, descripcion: '100', enum: '100' }
  ];
  reparto: Reparto; // cmabiar mas adelante por model de Repartos
  repartoHead: RepartoHead;
  repartoDet: RepartoDet[];
  repartoFoot: RepartoFoot[];
  cargando: boolean = false;
  totalElementos: number = 0;
  tamanhoPag: string = 'sm';
  ellipses: boolean = false;
  cantidadElementos: number = 0;
  pagina: number = 0;
  paginador: any;
  rutaPaginador: string = '/reparto/page/';
  timeLeft: number = 0;
  interval;
  timer: any;// = moment('000000', 'hmmss').format('HH:mm:ss'); 
  timeraux: any;
  errorGrupoReparto: boolean;
  //errorGrupoFactura: boolean;
  iniciar: boolean;
  finalizar: boolean;
  buscar: boolean;
  cancelar: boolean;
  user: any;
  existeStorage: boolean;
  depositoFinalizado: boolean;
  choferFinalizado: boolean;
  existeDetalle: boolean;
  totalGs: number;
  totalCantidad: number;
  totalCantidadCli: number;
  buscarDisabled: boolean;
  sucursales: Sucursal[] = [];
  seleccionSucursal: string;
  userRol: string;

  constructor(public router: Router, public _repartoService: RepartoService, private activatedRoute: ActivatedRoute, public _sucursalService: SucursalService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sidebarHide();
    //console.log('resize');
  }
  
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges() {
    this.sidebarHide();
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.grupoReparto = +params.get('grupo');
    });
    this.existeStorage = false;
    this.depositoFinalizado = false;
    this.choferFinalizado = false;
    this.existeDetalle = true;
    this.totalGs = 0;
    this.totalCantidad = 0;
    this.totalCantidadCli = 0;
    if (localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userRol = this.user.authorities[0];
    } else{ this.user = {};}

    //hay que buscar si existe un reparto no finalizado en el localstorage
    //si hay algo es porque no se finalizó
    this.timeraux = moment().startOf('day');
    if (localStorage.getItem('reparto')) {
      this.existeStorage = true;
      this.reparto = JSON.parse(localStorage.getItem('reparto'));
      this.repartoHead = this.reparto['head'];
      this.repartoDet  = this.reparto['det'];
      this.repartoFoot = this.reparto['foot'];
      this.cargando = false;
      for (let i = 0; i < this.repartoFoot.length; i++) {
        this.totalGs += this.repartoFoot[i].totalGs;
        this.totalCantidad += this.repartoFoot[i].cantidad;
        this.totalCantidadCli += this.repartoFoot[i].cantidadCliente;
      }
      //this.errorGrupoFactura = false;
      this.seleccionSucursal = this.repartoHead.codSucursal.toString();
      this.errorGrupoReparto = false;
      this.iniciar = false;
      this.finalizar = true;
      this.buscarDisabled = true;
      
      if (localStorage.getItem('duracionCamion')){
        let duracionCamion = localStorage.getItem('duracionCamion');
        let hora = parseInt(duracionCamion.substr(1,2),10);
        let min = parseInt(duracionCamion.substr(4,2),10);
        let seg = parseInt(duracionCamion.substr(7,2),10);
        let auxt = duracionCamion.replace('"','').replace(':','').replace(':','').replace('"','');
        this.timer = moment(auxt, 'hmmss').format('HH:mm:ss'); 
        this.timeraux.hours(hora).minutes(min).seconds(seg).milliseconds(0);
      }
    }else{
      this.timer = moment('000000', 'hmmss').format('HH:mm:ss'); 
      this.cargando = true;
      //this.grupoFactura = null;
      this.size = 20;
      this.cargando = false;
      this.timeraux.hours(0).minutes(0).seconds(0).milliseconds(0);
      this.errorGrupoReparto = false;
      //this.errorGrupoFactura = false;
      this.iniciar = true;
      this.buscarDisabled = false;
      this.finalizar = true;
      this.buscar = false;
      this.cancelar = false;
      this.repartoHead = {};
      this.repartoDet = [];
      this.repartoFoot = [];
      if (!this.grupoReparto)
        this.grupoReparto = null;
      else
        this.buscarReparto();

      this.loadSucursales(this.user.codEmpresa);
    }
    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      if (
        event.id === 1 &&
        event.url === event.urlAfterRedirects 
      ) {
        this.sidebarHide();//..... // here your code when page is refresh
      }
    });
  }

  loadSucursales(idEmpresa){
    this._sucursalService.getSucursales(idEmpresa).subscribe(resp => {
      this.sucursales = resp;
      this.seleccionSucursal = this.user.codSucursal;
    });
  }

  guardarStorage(reparto: Reparto) {
    localStorage.setItem('reparto', JSON.stringify(reparto));
    if (localStorage.getItem('reparto')) {
      this.reparto = JSON.parse(localStorage.getItem('reparto'));
    } else {
      localStorage.removeItem('reparto');
    }
  }

  sidebarHide() {
    'use strict';
    $(function () {
      $('.preloader').fadeOut();
    });
    jQuery(document).on('click', '.mega-dropdown', function (e) {
      e.stopPropagation();
    });
    let set = function () {
      $('body').addClass('mini-sidebar');
      $('.navbar-brand span').hide();
      $('.sidebartoggler i').addClass('ti-menu');
    };
    $().ready(set);
    $().on('resize', set);
  }

  buscarReparto() {
    if (this.grupoReparto == null)
      this.errorGrupoReparto = true;
    else
      this.errorGrupoReparto = false;
    
    /*if (this.grupoFactura == null)
      this.errorGrupoFactura = true;
    else
      this.errorGrupoFactura = false;*/

    if (/*!this.errorGrupoFactura &&*/ !this.errorGrupoReparto) {
      let codSucursal;
      if (this.seleccionSucursal)
        codSucursal = this.seleccionSucursal;
      else
        codSucursal = this.user.codSucursal;

      this.cargando = true;
        //si no existe, buscar en la BD
      this._repartoService.getReparto(/*this.grupoFactura,*/ this.grupoReparto, this.user.codEmpresa, codSucursal/*this.user.codSucursal*/)
      .subscribe((resp: Reparto) => {
        this.reparto = resp;
        for(let i=0; i <this.reparto['det'].length; i++){
          if (this.reparto['det'][i].controlDeposito == null)
            this.reparto['det'][i].controlDeposito = false;
          if (this.reparto['det'][i].controlCamion == null)
            this.reparto['det'][i].controlCamion = false;
        }
        this.repartoHead = resp['head'];
        this.repartoDet  = resp['det'];
        this.repartoFoot = resp['foot'];
        this.cargando = false;
        //this.errorGrupoFactura = false;
        this.errorGrupoReparto = false;
        //Hay que analizar los casos
        //Caso 1: cuando el codigo del empaque es nulo, quiere decir que todavía no se inició por el depositero. Validar
        if (this.repartoHead.codEmpaque === null){
          this.iniciar = true;
          this.depositoFinalizado = true;
          this.choferFinalizado = false;
        } else {
          //caso 2: cuando se inicio el deposito pero no el chofer. Sólo en este caso se debe habilitar el botón iniciar
          if (this.repartoHead.finDeposito === true && this.repartoHead.finCamion === false){
            this.iniciar = false;
            this.choferFinalizado = false;
            this.depositoFinalizado = false;
            // caso 3: si no entra en el if anterior quiere decir que el chofer ya finalizo su trabajo
          } else {
            this.iniciar = true;
            this.depositoFinalizado = false;
            this.choferFinalizado = true;
          }
        }
        //recorrer el foot para sumar agregar los totales
        for (let i = 0; i < this.repartoFoot.length; i++) {
          this.totalGs += this.repartoFoot[i].totalGs;
          this.totalCantidad += this.repartoFoot[i].cantidad;
          this.totalCantidadCli += this.repartoFoot[i].cantidadCliente;
        }
      },
        (err) => {
          Swal.fire('Error', 'No se encuentra el grupo de reparto en esa sucursal', 'error');
          this.cargando = false;
        }
      );
    }
  }

  cambioNumero(EVENTO) {
    this.size = EVENTO;
  }

  loadPage(page: number) {
    if (page !== this.paginador) {
      this.paginador = page - 1;
      this.router.navigate([this.rutaPaginador, this.paginador]);
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
        this.timeraux.add(1, 'seconds');
        this.timer = this.timeraux.format('HH:mm:ss');
        if (localStorage.getItem('duracionCamion')){
          localStorage.removeItem('duracionCamion');
          localStorage.setItem('duracionCamion', JSON.stringify(this.timer));
        }else localStorage.setItem('duracionCamion', JSON.stringify(this.timer));
    }, 1000);
    //si tiene detalle es porque ya existe en el localstorage
    if (this.existeStorage === true) {
      this.iniciar = true;
      this.finalizar = false;
      this.buscar = true;
      this.cancelar = true;
      this.buscarDisabled = true;
    }else{
      this.repartoHead.inicioCamion = true;
      this.repartoHead.finCamion = false;
      this.repartoHead.fechaInicioCamion = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.repartoHead.usuarioInicioCamion = this.user.sub;
      this.reparto['head'] = this.repartoHead;
      //meter en localstorage
      if (localStorage.getItem('reparto')) 
        localStorage.removeItem('reparto');
      else
        localStorage.setItem('reparto', JSON.stringify(this.reparto));
      
      this.iniciar = true;
      this.finalizar = false;
      this.buscar = true;
      this.cancelar = true;
      this.buscarDisabled = true;
    }
    
  }

  

  pauseTimer() {
    //recorrer el detalle y si tiene por lo menos un detalle confirmado, se tiene que poder finalizar, de lo contrario no finalizar
    for(let i=0; i<this.reparto['det'].length; i++){
      if (this.reparto['det'][i].controlCamion === false)
        this.existeDetalle = false;
      else
        this.existeDetalle = true;
    }
    if(this.existeDetalle){
      //debe mostrar un swal, está seguro que desea finalizar, si es si, recargar todo, si es no, continuar el timer
      Swal.fire({
        title: 'Ingrese su pin para finalizar',
        input: 'password',
        text: `Una vez finalizado ya no se podrá revertir la operación`,
        inputPlaceholder: 'Ingrese su pin',
        showCancelButton: true,
        confirmButtonText: 'Finalizar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: async (pass) => {
          if (pass === this.user.pin){
            //Swal.showLoading();
            //traer del storage
            if (localStorage.getItem('reparto'))
              this.reparto = JSON.parse(localStorage.getItem('reparto'));
          
            this.reparto['head'].finCamion = true;
            this.reparto['head'].fechaFinCamion = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
            this.reparto['head'].usuarioFinCamion = this.user.sub;
            this.reparto['head'].duracionCamion = this.timer;
            let resp: Reparto;
            try {
              resp = await this._repartoService.putReparto(this.reparto).toPromise();  
            } catch (error) {
              console.log('EL ERROR ES: ', error);
            }
            if (resp){
              if (localStorage.getItem('reparto'))
                  localStorage.removeItem('reparto');
              if (localStorage.getItem('duracionCamion'))
              localStorage.removeItem('duracionCamion');
              //reiniciar todo
              clearInterval(this.interval);
              this.finalizar = true;
              this.cancelar = false;
              this.ngOnInit();
            }else{
              Swal.showValidationMessage(`Algo ha ocurrido mal`);
            }
            /*this._repartoService.putReparto(this.reparto)
            .subscribe(reparto => {
                if (localStorage.getItem('reparto'))
                    localStorage.removeItem('reparto');
                if (localStorage.getItem('duracionCamion'))
                localStorage.removeItem('duracionCamion');
                //reiniciar todo
                clearInterval(this.interval);
                this.finalizar = true;
                this.cancelar = false;
                this.ngOnInit();
                Swal.fire('Excelente', `La operación ha finalizado con éxito`, 'success');
              }, 
              err => Swal.fire(err.error.error, err.error.message, 'error')
            );*/
          }else{
            Swal.showValidationMessage(`el pin ingresado es incorrecto`);
          }
        },
          allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {//result
        if (result.value){
          Swal.fire('Excelente', `La operación ha finalizado con éxito`, 'success');
          this.router.navigate(['facturas/']);
        }else
        //Swal.fire('Error', 'Algo ha ocurrido mal', 'error');
          Swal.showValidationMessage(`La operación no ha finalizado correctamente`);
      });
    }else{
      Swal.fire({
        title: 'Atención',
        text: `Debe confirmar todos los ítems`,
        type: 'warning',
        showCancelButton: false,
        //confirmButtonColor: '#3085d6',
        //cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        confirmButtonClass: 'btn btn-warning',
        buttonsStyling: false,
        reverseButtons: true
      });
    }
  }

  confirmar(item: RepartoDet) {
    //lo que hay que hacer por un lado es colocar el campo confirmado a true
    //deshabilitar el botón confirmar
    //habilitar el botón deshacer
    //setear los valores del detalle
    item.controlCamion = true;
    item.fechaCamion = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    item.usuarioCamion = this.user.sub;
    //por otro lado, hay que ir completando los campos de la cabecera como el tiempo por ejemplo
    //sacar del localstorage el reparto
    //meter en el localstorage los nuevos valores
    this.reparto['det'] = this.repartoDet;
     //meter en localstorage
    if (localStorage.getItem('reparto')){
     localStorage.removeItem('reparto');
     localStorage.setItem('reparto', JSON.stringify(this.reparto));
    }else
     localStorage.setItem('reparto', JSON.stringify(this.reparto));


  }

  desconf(item: RepartoDet) {
    //lo que hay que hacer es colocar el campo confirmado a true
    //deshabilitar el botón confirmar
    //habilitar el botón deshacer
    item.controlCamion = false;
    item.fechaCamion = null;
    item.usuarioCamion = null;
    //por otro lado, hay que ir completando los campos de la cabecera como el tiempo por ejemplo
    //sacar del localstorage el reparto
    //meter en el localstorage los nuevos valores
    this.reparto['det'] = this.repartoDet;
     //meter en localstorage
    if (localStorage.getItem('reparto')){
     localStorage.removeItem('reparto');
     localStorage.setItem('reparto', JSON.stringify(this.reparto));
    }else
     localStorage.setItem('reparto', JSON.stringify(this.reparto));
  }

  cancel(){
    if (localStorage.getItem('reparto'))
      localStorage.removeItem('reparto');
    if (localStorage.getItem('duracionCamion'))
    localStorage.removeItem('duracionCamion');
    //reiniciar todo
    clearInterval(this.interval);
    this.finalizar = true;
    this.cancelar = false;
    this.router.navigate(['facturas/']);
    //this.ngOnInit();
  }

}

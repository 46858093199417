import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { BASE_URL } from '../../config/config';
import { tap, map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { Parametro } from '../../models/parametro.model';


@Injectable()
export class ParametroService {
  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _loginService: LoginService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + this._loginService.token
    });
  }

  get(id: string) {
    let url = BASE_URL + 'parametros?idEmpresa=' + id;
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  delete(id: string): Observable<Parametro> {
    return this.http.delete<Parametro>(`${BASE_URL}parametros/${id}`, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }


  getById(id) {
    let url = BASE_URL + 'parametros/' + id;
    console.log(url);
    return this.http.get(url, { headers: this.headers })
      .map((response: any) => {
        return response;
      });
  }

  create(parametro: Parametro): Observable<Parametro> {
    return this.http.post(BASE_URL + 'parametros', parametro, { headers: this.headers })
      .pipe(
        map((response: any) => response),
        catchError(e => {
          console.error('ERROR', e.error);
          Swal.fire(e.error.header, e.error.message, 'error');
          return throwError(e);
        })
      );
  }
  update(parametro: Parametro): Observable<Parametro> {
    return this.http.put<any>(BASE_URL + 'parametros', parametro, { headers: this.headers }).pipe(
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }


}

import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../../models/empresa.model';
import { EmpresaService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-empresa-detalle',
  templateUrl: './empresa-detalle.component.html',
  styles: []
})
export class EmpresaDetalleComponent implements OnInit {
  titulo: string = 'Detalles de la empresa';
  empresa: Empresa = null;
  public imagen = null;
  constructor(private _empresaService: EmpresaService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params.get('id'));
      let id: number = +params.get('id');
      if (id) {
 /*        this._empresaService.getempresa(id).subscribe(
          data => {
          this.empresa = data;
            console.log(data.img);
              this._empresaService.getImagen(data.img).subscribe(
              resp => {
                console.log(resp);
                this.createImageFromBlob(resp);
                // return this.imagen = resp;
              }, error => {
                console.log(error);
              });
          }); */
      }
    });
  }

 /*  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imagen = reader.result;
    }, false);

    if (image) {
       reader.readAsDataURL(image);
    }
   } */

  ngOnInit() {

  }

}

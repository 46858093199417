import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { ErrModel } from '../../modelos-demo/ErrModel.model';
import { Empresa } from '../../models/empresa.model';
import { Rol } from '../../models/rol.model';
import { User } from '../../models/user.model';
import { Usuario } from '../../models/usuario.model';
import { EmpresaService } from '../../services/empresa/empresa.service';
import { LoginService } from '../../services/login/login.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Sucursal } from '../../models/sucursal.model';
import { SucursalService } from '../../services/sucursal/sucursal.service';
import { RolService } from '../../services/service.index';
import * as moment from 'moment';


@Component({
  selector: 'app-form-usuario',
  templateUrl: './form-usuario.component.html',
  styles: []
})
export class FormUsuarioComponent implements OnInit {
  empresa: Empresa;
  imagenSubir: File;
  imagenTemp: any;
  sinImagen: string = './assets/images/users/user_img.png';
  roles: Rol[] = [];
  sucursales: Sucursal[] = [];
  seleccionRol: string;
  seleccionSucursal: string;
  selectSucursal: Sucursal;
  user: any;
  usuario: Usuario = new Usuario();
  password: string;
  passwordCfr: string;
  errores: ErrModel[] = [];
  passwordInput: boolean = false;
  pin: string;
  //empresa 1
  //sucursal 0

  constructor(
    private _loginService: LoginService,
    private _usuarioService: UsuarioService,
    private toastr: ToastrService,
    private _empresaService: EmpresaService,
    private _rolService: RolService,
    private _sucursalService: SucursalService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    // this.usuario.nombrePersona = '';
    this.usuario.codPersonaErp = '';
    this.usuario.username = '';
    // this.usuario.rol = null;
    // this.usuario.sucursal = null;
    this.usuario.enabled = true;
    this.usuario.createdAt = null;
    this.usuario.modifiedAt = null;
    // this.usuario.createdBy = 'this._loginService.user.username';
    // this.usuario.modifiedBy = 'this._loginService.user.username';
    this.usuario.password = null;
    // this.usuario.img = '';
    this.password = null;
    this.passwordCfr = null;
  }

  ngOnInit() {
    //this.user = this._loginService.user;
    if (localStorage.getItem('user')){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadSucursales(this.user.codEmpresa);
    }else{  this.user = {};}
    this.activatedRoute.paramMap.subscribe(params => {
      let id: number = +params.get('codusuario');
      if (id) {
        this._usuarioService.getById(id).subscribe((resp) => {
          this.usuario = resp;
          this.seleccionRol = this.usuario.rol.nombreRol;
          this.seleccionSucursal = this.usuario.codSucursal;
          /*if (this.usuario.sucursal) {
            this.seleccionSucursal = resp.sucursal.nombreSucursal;
          }*/
          //console.log(this.usuario);
        });
      } else {
        this.passwordInput = true;
      }
      this.loadRoles();
      //this.loadSucursales(/*this.user.idEmpresa*/1);
    });
  }

  create(): any {
    /*if (!this.usuario.rol) {
      this.invalido('El rol no puede ser nulo');
      return;
    }*/
    if (!this.usuario.nombrePersona){
      this.invalido('El nombre de la persona no puede estar vacío');
      return;
    }

    if (!this.usuario.username){
      this.invalido('El usuario no puede estar vacío');
      return;
    }
    if (this.password === this.passwordCfr && (this.password != null || this.password !== '')) {
      this.usuario.password = this.password;
    } else {
      this.usuario.password = null;
      this.password = null;
      this.passwordCfr = null;
      this.invalido('las contraseñas deben ser identicas');
      return;
    }
    this.usuario.codEmpresa = '1';//this._loginService.user.idEmpresa;
    this.usuario.codSucursal = this.seleccionSucursal;
    this.usuario.codPersonaErp = '0';
    this.usuario.createdAt =  moment(new Date()).format('YYYY-MM-DD HH:mm:ss');//new Date();
    this.usuario.createdBy = this.user.sub;
    this._usuarioService.create(this.usuario)
      .subscribe(
        (usuario: Usuario) => {
          swal.fire('Nuevo usuario', `El usuario ${this.usuario.username} ha sido creado con éxito`, 'success');
          this.router.navigate(['/usuarios/page', 0]);
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          this.errores = err.error.errors;
          console.error('Código del error: ' + err.status);
        }
      );

  }

  update(): void {
    if (this.password === this.passwordCfr && (this.password != null )) {
      this.usuario.password = this.password;
    } else if (this.password === this.passwordCfr && (this.password === null || this.password === '')) {
      this.usuario.password = null;
      this.password = null;
      this.passwordCfr = null;
    } else {
      this.usuario.password = null;
      this.password = null;
      this.passwordCfr = null;
      this.invalido('las contraseñas deben ser identicas');
      return;
    }
    this.usuario.codSucursal = this.seleccionSucursal;
    this._usuarioService.update(this.usuario)
      .subscribe(
        (json: Usuario) => {
          this.router.navigate(['/usuarios']);
          //  console.log("json: " + json.usuario);
          if (this.imagenSubir) {
            //  this.cambiarImagen(this.usuario.codUsuario);
          }
          swal.fire('Excelente', `El usuario : ${json.username} ha sido actualizado con éxito`, 'success');
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          this.errores = err.error.errors;
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
  }

  loadRoles() {
    this._rolService.getRoles().subscribe(resp => {
      this.roles = resp;
    });
  }

  loadSucursales(idEmpresa){
    this._sucursalService.getSucursales(idEmpresa).subscribe(resp => {
      this.sucursales = resp;
    });
  }
  seleccionImage(archivo: File) {
    // si  no existe no hacer nada
    if (!archivo) {
      this.imagenSubir = null;
      return;
    }
    if (archivo.type.indexOf('image') < 0) {
      swal.fire('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }
    this.imagenSubir = archivo;
    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL(archivo);
    reader.onloadend = () => {
      //console.log(reader.result);
      this.imagenTemp = reader.result;
    };
  }

  cambiarImagen(cod) {
    this._usuarioService.uploadImage(this.imagenSubir, cod).subscribe((usuario: Usuario) => {
      this.usuario = usuario;
      this.imagenTemp = null;
      //  $('#uploadedfile').val(null);
    });
    //  this._usuarioService.cambiarImagen( this.imagenSubir, this.usuario._id );
  }

 cambioRol(event) {
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].nombreRol === this.seleccionRol) {
        this.usuario.rol = this.roles[i];
        return;
      }
    }
  }
  cambioSucursal(event) {
    for (let i = 0; i < this.sucursales.length; i++) {
      if (this.sucursales[i].nombreSucursal === this.seleccionSucursal) {
        //this.usuario.sucursal = this.sucursales[i];
        //console.log(this.sucursales[i].nombreSucursal);
        return;
      }
    }
  }

  togglePassInput() {
    if (!this.passwordInput) {
      this.passwordInput = true;
    } else {
      this.passwordInput = false;
    }
  }

  invalido(invalido) {
    this.toastr.error(invalido, 'Invalido',
      { timeOut: 1500 });
  }

  error(err) {
    this.toastr.error(err, 'Error',
      { timeOut: 2500 });
  }



}


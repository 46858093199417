import { Component } from '@angular/core';

import { SettingsService } from './services/service.index';
import { SwPush } from '@angular/service-worker';
import { PushNotificationService } from './services/pushNotification/push-notification.service';


const VAPID_PUBLIC = 'BMUMJoYDw13AfJjHb9BH3aoX7mmb_cZHVNbK3NMxJb8OMrsqeyoVQgmwZArlKiyzrkUhahX5ox1qOoTqzBjoODM';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor( public _ajustes: SettingsService, swPush: SwPush, pushService: PushNotificationService ) {
    /*if (swPush.isEnabled) {
      swPush.requestSubscription({serverPublicKey: VAPID_PUBLIC,})
        .then(subscription => {
          // send subscription to the server
          pushService.sendSubscriptionToTheServer(subscription).subscribe();
        })
        .catch(console.error);
    }*/
  }

}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Usuario } from '../../models/usuario.model';
import { LoginService } from '../../services/service.index';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: []
})
export class UsuariosComponent implements OnInit {
  usuarios: Usuario[];
  //sinImagen: string = './assets/images/users/user_img.png';
  cargando: boolean = false;
  paginador: any = 0;
  totalElementos: number = 0;
  busqueda: string = '';
  pageSize: number = 0;
  page: number = 0;


  constructor(private _usuarioService: UsuarioService,
    private _loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.busqueda = '';
    this.usuarios = [];
    this.getUsuarios();
  }

  getUsuarios() {
    this.cargando = true;
    this._usuarioService.getUsuariosAll().subscribe((resp: any) => {
        this.usuarios = resp as Usuario[];
        this.cargando = false;
    });
  }

  ver(item){
    console.log(item);
  }

  gotoedit(item: Usuario){
    if(item.codUsuario)
      this.router.navigate(['usuarios/formulario/', item.codUsuario]);
  }
}

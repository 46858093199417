import { Component, OnInit } from '@angular/core';

import { SidebarService, UsuarioService } from '../../services/service.index';
import { User} from '../../models/user.model';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  user: User;
  permiso: boolean;
  side: any[];

  constructor(
    public _sidebar: SidebarService,
    public _loginService: LoginService
  ) { }

  ngOnInit() {
    this.side = [];
    this.permiso = false;
    this.user = this._loginService.user;
    for(let i=0; i<this._sidebar.menu.length; i++){
      for (let j=0; j<this._sidebar.menu[i].rol.length; j++){
        if(this._sidebar.menu[i].rol[j] === this.user.authorities[0]){
          this.side.push(this._sidebar.menu[i]);
        }
      }
    }
  }

}

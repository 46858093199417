import { Component, OnInit } from '@angular/core';
import { SubirArchivoService } from '../../services/service.index';
import { ModalUploadService } from './modal-upload.service';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styles: []
})
export class ModalUploadComponent implements OnInit {
  imagenSubir: File;
  imagenTemp: any;
  constructor(
    public _subirArchivoService: SubirArchivoService,
    public _modalUpload: ModalUploadService
  ) { }

  ngOnInit() {
  }

  seleccionImage( archivo: File ) {
    // si  no existe no hacer nada
      if ( !archivo ) {
        this.imagenSubir = null;
        return;
      }
      if ( archivo.type.indexOf('image') < 0 ) {
      /*   swal('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error'); */
        this.imagenSubir = null;
        return;
      }

      this.imagenSubir = archivo;

      let reader = new FileReader();
      let urlImagenTemp = reader.readAsDataURL( archivo );

      reader.onloadend = () => { this.imagenTemp = reader.result;
      };

    }
    subirImagen() {
      this._subirArchivoService.subirArchivo(this.imagenSubir,
                                             this._modalUpload.tipo,
                                             this._modalUpload.id)
                                .then(respuesta => {
                                  this._modalUpload.notificacion.emit(respuesta );
                                  this.cerrarModal();
                                })
                                .catch(error => {
                                  console.log('Error en la carga');
                                });
    }
    cerrarModal() {
        this. imagenSubir = null;
        this.imagenTemp = null;
        this._modalUpload.ocultarModal();
    }

}

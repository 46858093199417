import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit {
 // Doughnut
 public doughnutChartLabels: Label[] = ['Recibidos', 'No recibidos'];
 public doughnutChartData: MultiDataSet = [
   [250, 130],
 ];
 public doughnutChartType: ChartType = 'doughnut';

 constructor() { }

 ngOnInit() {
 }
}

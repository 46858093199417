import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { User } from '../../models/user.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login/login.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Parametro } from '../../models/parametro.model';
import { ParametroService } from '../../services/parametro/parametro.service';
@Component({
  selector: 'app-form-parametros',
  templateUrl: './form-parametros.component.html',
  styles: []
})
export class FormParametrosComponent implements OnInit {
  parametro: Parametro = new Parametro();
  user: User;
  ocultar: boolean = true;
  opciones: any[] = [
    { id: true, descripcion: 'SI' },
    { id: false, descripcion: 'NO' }
  ];
  constructor(
    private _loginService: LoginService,
    private toastr: ToastrService,
    private _parametrosServices: ParametroService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.parametro.id = null;
    this.parametro.idEmpresa = this._loginService.user.idEmpresa;
    this.parametro.clave = '';
    this.parametro.valor = '';
    this.parametro.descripcion = '';
    this.parametro.system = false;
    this.parametro.class = '';

  }

  ngOnInit() {
    this.user = this._loginService.user;

    this.activatedRoute.paramMap.subscribe(params => {
      let id: string = params.get('id');
      console.log(params.get('id'));
      console.log(id);
      if (id) {
        this._parametrosServices.getById(id).subscribe((p) => {
          this.parametro = p;
        });
      }
      if (this.parametro.system == true) {
        this.ocultar = false;
      } else {
        this.ocultar = true;
      }
    });
  }

  create() {
    console.error(this.parametro);
    if (this.parametro.system == null) {
      this.invalido('System no puede ser null')
      return;
    }
    if (this.parametro.system) {
      if (this.parametro.valor == '' || this.parametro.valor.length < 3) {
        this.invalido('Se debe asignar valor, en caso system sea "Si"');
        return;
      }
    }
    /*     if (this.parametro.system =) {
          console.log('entro')
          if ( this.parametro.valor == '' || this.parametro.valor == null  || this.parametro.valor.length < 2) {
            console.log('entro')
            this.invalido('Se debe asignar valor, en caso system sea "Si"');
            return;
          }
        } */
    this._parametrosServices.create(this.parametro)
      .subscribe(
        bancos => {
          this.router.navigate(['/parametros']);
          swal.fire('Nuevo parametro', `El parametro ${this.parametro.descripcion} ha sido creado con éxito`, 'success');
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
  }

  update() {
    if (this.parametro.system == null) {
      this.invalido('System no puede ser null')
      return;
    }
    if (this.parametro.system) {
      if (this.parametro.valor == '' || this.parametro.valor.length < 3) {
        this.invalido('Se debe asignar valor, en caso system sea "Si"');
        return;
      }
    }
    this._parametrosServices.update(this.parametro)
      .subscribe(
        json => {
          this.router.navigate(['/parametros']);
          swal.fire('Parametro Actualizado', `Parametro  : ${json.descripcion}`, 'success');
        },
        err => {
          if (!err.error) {
            this.error('500 (Internal Server Error)');
            return;
          }
          console.error('Código del error desde el backend: ' + err.status);
        }
      );
  }

  invalido(i) {
    this.toastr.error(i, 'Invalido',
      { timeOut: 1500 });
  }

  error(err) {
    this.toastr.error(err, 'Error',
      { timeOut: 2500 });
  }

  onChangeParametros(e) {
    console.log(e);
    console.log(this.parametro.system);
    if (this.parametro.system == true) {
      this.ocultar = false;
    } else {
      this.ocultar = true;
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class SidebarService {

  menu: any = [
    {
      titulo: 'Estado de repartos',
      icono: 'mdi mdi-traffic-light',
      url: '/estados',
      rol: ['ROLE_ADMIN', 'ROLE_DEPOSITERO', 'ROLE_CHOFER', 'ROLE_FACTURADOR']
    },
    {
      titulo: 'Control deposito',
      icono: 'mdi mdi-cube-outline',
      url: '/reparto',
      rol: ['ROLE_ADMIN', 'ROLE_DEPOSITERO']
    },
    {
      titulo:'Control chofer',
      icono:'mdi mdi-truck',
      url: '/facturas',
      rol: ['ROLE_ADMIN', 'ROLE_CHOFER']
    },
    {
      titulo: 'Configuración',
      icono: 'fa fa-lg fa-fw fa-cogs',
      rol: ['ROLE_ADMIN'],
      submenu: [
        { titulo: 'Usuarios', url: '/usuarios' },
      ]
    },
    
  ];

  constructor() { }

}
